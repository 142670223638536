import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Layout, Typography, Button, Row, Col, Card, Spin } from 'antd';

import moment from 'moment';
import 'moment/min/locales';

import Core from "../../Core.js";

import Speakers from '../../containers/Speakers';
import ScheduleContainer from '../../containers/Schedule/';
import Countdown from "../../components/FlipCountDown/Countdown.js";
import { CalendarOutlined } from '@ant-design/icons';

import './index.scss';

const { Title } = Typography;
moment.locale('pt');

export default class MainPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            redirectLogin: false,
            redirectPlayer: false,
            data: []
        };
    }

    componentDidMount() {
        if (Core.data().config.no_home){
          this.setState({ redirectPlayer: true });
        }
        if (Core.data().config.private == true && Core.data().login == null) {
            this.setState({ redirectLogin: true });
        } else {
            this.loadHome();
        }
    }

    setRedirect = () => {
        this.setState({
            redirectPlayer: true
        });
    }

    loadHome() {
        Core.apiData(`pages/home.json`, {
            onSuccess: (data) => {
                if (data.json) {
                    this.setState({
                        data: data.json,
                    });
                } else {
                    this.setState({ error: true });
                    console.log("Schedule data is not a JSON!");
                }
            },
            onFail: (data) => {
                this.setState({ error: true });
                console.log("Schedule data failed.", data);
            }
        });
    }

    render() {
        const { redirectLogin, redirectPlayer, redirect } = this.state;
        const isMobile = window.innerWidth <= 500;
        let hasCountDown = false;
        let eventRange = "";
        let startDate = null;
        if (this.state.data.startDate && this.state.data.endDate) {
          startDate = moment(this.state.data.startDate, 'YYYY-MM-DD HH:mm:ss');
          const endDate = moment(this.state.data.endDate, 'YYYY-MM-DD HH:mm:ss');

          const capitalizeStartMonth = startDate ? startDate.locale("pt").format("MMMM").charAt(0).toUpperCase() + startDate.locale("pt").format("MMMM").slice(1) : null;
          const capitalizeEndMonth = endDate.locale("pt").format("MMMM").charAt(0).toUpperCase() + endDate.locale("pt").format("MMMM").slice(1);

            if (endDate.format('D') == startDate.format('D')
                && endDate.format('M') == startDate.format('M')
                && endDate.format('YYYY') == startDate.format('YYYY'))  {
                eventRange =  `${startDate.format('D')} de ${capitalizeEndMonth} ${endDate.format('Y')}`;
            } else if(endDate.format('M') == startDate.format('M')){
                eventRange =  `${startDate.format('D')} a ${endDate.format('D')} ${capitalizeEndMonth} ${endDate.format('Y')}`;
            } else {
                eventRange =  `${startDate.format('D')} de ${capitalizeStartMonth} a ${endDate.format('d')} de ${capitalizeEndMonth} ${endDate.format('Y')}`;
            }
        }
        if (redirectLogin) {
          window.location.assign("/login");
        }
        if (redirectPlayer) {
            if(this.state.data.eventLink == '' || this.state.data.eventLink == null){
                window.location.assign("/player")
            } else {
                return window.location.assign(this.state.data.eventLink);
            }
        }
        if (redirect) {
          window.location.assign("/registo");
        }

        return (
            <div className="main">
                <Layout>
                  <div className="main--wrapper">
                  <Row gutter={16} className="main-row" type="flex" align="middle" justify="center">
                    <Col className="gutter-row" xs={12} sm={12} md={8} lg={8} xl={8}>
                      <img src="/data/images/logo-mini.png" />
                    </Col>
                    <Col className="gutter-row" xs={0} sm={0} md={8} lg={8} xl={8}>
                      <div className="main--firts-section">
                          <div className="main--title">
                            <h1 className="main--title-h1"><b>{this.state.data.title}</b></h1>
                            <p className="main--title-p"><b>{this.state.data.content}</b></p>
                          </div>
                      </div>
                      <div className="main--dates">
                        <p><CalendarOutlined /> {eventRange}</p>
                      </div>
                      <div className="main--countdown">
                        { this.state.data.startDate ?
                          <Countdown
                            date={startDate.format('YYYY/MM/DD HH:mm:ss')}
                            useFlipView={true}
                            expiryMsg={""}
                          /> : null }
                      </div>
                      <div className="main--event">
                          {/*<Button className="main--btn" type="primary" size="large" onClick={this.setRedirect}>{this.state.data.eventView || "Assistir ao evento"}</Button>*/}
                          <Button className="main--btn" type="primary" size="large" href={this.state.data.eventLink}>{this.state.data.eventView || "Assistir ao evento"}</Button>
                      </div>
                    </Col>
                    <Col className="gutter-row" xs={12} sm={12} md={8} lg={8} xl={8}>
                      <img src="/data/images/logoel.png" />
                    </Col>
                    <Col className="gutter-row" xs={24} sm={24} md={0} lg={0} xl={0}>
                      <div className="main--firts-section">
                          <div className="main--title">
                            <h1 className="main--title-h1"><b>{this.state.data.title}</b></h1>
                            <p className="main--title-p"><b>{this.state.data.content}</b></p>
                          </div>
                      </div>
                      <div className="main--dates">
                        <p><CalendarOutlined /> {eventRange}</p>
                      </div>
                      <div className="main--countdown">
                        { this.state.data.startDate ?
                          <Countdown
                            date={startDate.format('YYYY/MM/DD HH:mm:ss')}
                            useFlipView={true}
                            expiryMsg={""}
                          /> : null }
                      </div>
                      <div className="main--event">
                          {/*<Button className="main--btn" type="primary" size="large" onClick={this.setRedirect}>{this.state.data.eventView || "Assistir ao evento"}</Button>*/}
                          <Button className="main--btn" type="primary" size="large" href={this.state.data.eventLink}>{this.state.data.eventView || "Assistir ao evento"}</Button>
                      </div>
                    </Col>
                  </Row>

                  </div>
                </Layout>
            </div>
        );
    }
}
